/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    a: "a",
    div: "div",
    p: "p",
    h2: "h2",
    h3: "h3",
    em: "em",
    ul: "ul",
    li: "li",
    img: "img"
  }, _provideComponents(), props.components), {LandingPageCta} = _components;
  if (!LandingPageCta) _missingMdxReference("LandingPageCta", true);
  return React.createElement(React.Fragment, null, React.createElement(_components.h1, {
    className: "c-md-h1",
    id: "hearing-aids-and-ear-infections",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#hearing-aids-and-ear-infections",
    "aria-label": "hearing aids and ear infections permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Hearing Aids and Ear Infections"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "If you wear hearing aids, you may be wondering if you might be at risk for ear infections. Or, if you’ve had ear infections before, you might wonder if your devices will increase your chances of getting new ear infections more frequently. Contrary to popular belief, ear infections are not limited to children. People of any age can get ear infections, but those who wear hearing aids have a slightly higher chance of both getting a new infection and developing subsequent reinfections."), "\n", React.createElement(LandingPageCta, {
    copy: "Try our hearing aids now",
    classes: "c-cta--content"
  }), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "what-causes-ear-infections",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#what-causes-ear-infections",
    "aria-label": "what causes ear infections permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "What Causes Ear Infections?"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Ear infections can develop due to a number of reasons, such as a complication from a cold or excess moisture; however, ", React.createElement("a", {
    href: "https://my.clevelandclinic.org/health/diseases/8613-ear-infection-otitis-media",
    target: "_blank"
  }, "the majority of ear infections are caused by bacteria or viruses"), ". Most commonly, an ear infection will result from fluid build-up in the middle ear. Other times, the infection can arise in the outer ear canal. When this type of ear infection occurs, it is usually the result of water exposure and is often referred to as “swimmer’s ear.”"), "\n", React.createElement(_components.h3, {
    className: "c-md-h3",
    id: "can-hearing-aids-lead-to-ear-infections",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#can-hearing-aids-lead-to-ear-infections",
    "aria-label": "can hearing aids lead to ear infections permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Can Hearing Aids Lead to Ear Infections?"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Hearing aids do not actually cause ear infections. However, they can harbor the bacteria and viruses that do. A lack of proper and regular cleaning can lead to a buildup of bacteria on your hearing aids that may result in an infection. For this reason, it’s important to clean your devices thoroughly and consistently."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "how-to-properly-clean-hearing-aids",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#how-to-properly-clean-hearing-aids",
    "aria-label": "how to properly clean hearing aids permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "How to Properly Clean Hearing Aids"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Although powerful, hearing aids are just like any other electronic device and, therefore, should be cared for and regularly cleaned. Not only will establishing a consistent ", React.createElement(_components.a, {
    href: "/resources/all-articles/how-to-keep-your-hearing-aids-in-tip-top-shape-for-years-to-come/",
    className: "c-md-a"
  }, "hearing aid cleaning routine"), " help reduce your chances of getting an ear infection, it will also help keep the aids in tip top shape."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Hearing experts recommend a light cleaning of your devices once per week, whether you wear them daily or sporadically. Hearing aids can accumulate a buildup of earwax, dirt, and dust, which can contribute to poor device performance, bacterial growth, and consequently, ear infections."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Most hearing aids usually come with cleaning tools at the time of purchase that are specially designed to be used on your devices, including a small brush that can help to break up dirt and earwax buildup. To ensure optimal cleaning, be sure to use the cleaning tools that are made for your specific hearing aids. However, a soft, dry cloth can be used to gently clean the outside of your devices. More thorough cleanings can be performed once a month and professional cleanings with a hearing care professional should be scheduled twice per year."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.em, null, "Never use water, soap, or any other products to clean your hearing aids, as excess moisture can both damage your devices and create the perfect breeding grounds for bacteria to develop and thrive in.")), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "signs-you-may-have-an-ear-infection",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#signs-you-may-have-an-ear-infection",
    "aria-label": "signs you may have an ear infection permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Signs You May Have an Ear Infection"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "If you have an ear infection, chances are it will not go undetected. Ear infection symptoms can vary from a mild irritation to severely painful. If you are experiencing any of the following symptoms, it is best to remove your hearing aids and seek medical attention as soon as possible:"), "\n", React.createElement(_components.ul, {
    className: "c-md-list"
  }, "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Pain inside the ear"), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Swelling inside the ear"), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Ringing of the ears"), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Ear irritation"), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Ear secretions"), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Dull or muffled sounds"), "\n"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.img, {
    src: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/ear-infection.png",
    alt: "women in pain due to ear infection",
    className: "c-md-img"
  }), "\n", React.createElement(_components.em, null, "Ear infections can be quite painful and should be taken seriously.")), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "should-i-wear-hearing-aids-if-i-have-an-ear-infection-or-am-prone-to-ear-infections",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#should-i-wear-hearing-aids-if-i-have-an-ear-infection-or-am-prone-to-ear-infections",
    "aria-label": "should i wear hearing aids if i have an ear infection or am prone to ear infections permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Should I Wear Hearing Aids If I Have an Ear Infection or Am Prone to Ear Infections?"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "While hearing aids don’t actually cause ear infections, your devices may not work as well as they normally do solely because the infection creates inflammation within the ear canal. Even without an infection, hearing aids should not be worn 24/7. They should be removed at night in order to give both your body and brain a rest."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "During an ear infection, you should clean your ears and try to keep them dry while healing from an ear infection, as excess moisture can both prolong an existing ear infection as well as contribute to new bacterial growth within the ear canal."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Although most ear infections go away on their own, your doctor may prescribe antibiotic drops to help accelerate the healing process. If you are prescribed ear drops, it’s important to remove any excess moisture from your ear drops or drainage from the ear canal. You can do so by gently pressing a cotton ball against the opening of your ear and tilting your head so the excess moisture can trickle out of your ear and onto the cotton ball. Do not try to push the cotton ball (or any object) into your ear."), "\n", React.createElement(LandingPageCta, {
    copy: "Try our hearing aids now",
    classes: "c-cta--content"
  }));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
